import { FC } from 'react';

import { ImageSystemsLogo, LogoCentered } from 'assets/icons/svg';

import style from 'assets/styles/sign.module.scss';
import { SignupForm } from 'modules/SignupForm';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'desktop/ipc';

export const Signup: FC = () => {
	const navigate = useNavigate();

	return (
		<div className={style.container}>
			<div className={`loginimage ${style.leftHalfContainer}`}>
				<div className={style.leftWrapper}>
					<div className={style.logo}>
						<LogoCentered />
					</div>
					<div className={style.company}>
						<ImageSystemsLogo />
					</div>
				</div>
			</div>
			<div className={style.rightHalfContainer}>
				<div className={style.signFormWrapper}>
					{!isDesktop && (
						<div className={style.header}>
							<p className={style.headerText}>Sign Up</p>
						</div>
					)}
					<SignupForm />
					<div className={style.infoSignup}>
						<div
							onClick={() => navigate('/login')}
							style={{
								color: 'var(--text-normal)',
								fontWeight: '600',
								paddingTop: '0.25rem',
								cursor: 'pointer',
							}}
						>
							Return to login
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
