import React from 'react';
import { Modal } from 'antd';
import style from 'assets/styles/terms.module.scss';

interface TermsProps {
	isOpen: boolean;
	onClose: () => void;
}

export const Terms: React.FC<TermsProps> = ({ isOpen, onClose }) => {
	return (
		<Modal
			title="Terms and Conditions"
			open={isOpen}
			onCancel={onClose}
			footer={null}
			width={800}
			className={style.modal}
		>
			<div className={style.scrollableContent}>
				<div className={style.section}>
					<p>
						<strong>1. Scope and use of the subscription</strong>
					</p>
					<p>
						Image Systems subscription grants the legal entity that has taken
						out the subscription (the "Customer") the right to use the Product
						and Services (the "Services"). The subscription may not be used by
						other organizations.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>2. Acceptance of the subscription</strong>
					</p>
					<p>
						Image Systems subscription terms are agreed by the Customer by
						placing a Purchase Order based on a formal quote (the "Formal
						Quote"). The Purchase Order and the Formal Quote will represent the
						agreement (the "Agreement") between the Customer and Image Systems
						(the "Parties").
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>3. Duration and termination of the subscription</strong>
					</p>
					<p>
						The subscriptions run from the date, as specified in the Formal
						Quote. At the end of the Subscription period, the subscription will
						automatically renew for a period of twelve months unless terminated
						by the Customer or expressively agreed by Image Systems.
						Subscriptions may be terminated by email to sales@imagesystems.se,
						one month before the expiry of the subscription (i.e. for
						subscriptions with yearly renewal term running from April to March,
						the termination notice must be received before the end of February).
						The agreement cannot be terminated for a period of subscription
						already initiated and the Customer will not be entitled to a refund
						for that subscription period.
					</p>
					<p>
						Image Systems shall (without prejudice to any other rights or
						remedies it may have) be allowed to terminate the subscription
						immediately without giving the Customer prior notice if the Services
						or subscription is misused (including, but not limited to, use by
						other organizations).
					</p>
					<p>
						In the event of any of the Parties filing for bankruptcy, the
						Agreement will be terminated, and all Services will be terminated
						immediately, unless otherwise specifically agreed in writing between
						the Parties.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>4. Price and payment terms</strong>
					</p>
					<p>
						Prices for each service item are listed on the Formal Quote. Prices
						may be updated after the initial subscription period.
					</p>
					<p>
						The invoicing periods runs according to the Formal Quote. The
						payment term is as specified on the Formal Quote. If the
						subscription is not paid at the due date access to the Services may
						be blocked until receipt of payment.
					</p>
					<p>
						Image Systems will not be responsible for any loss, damage, costs,
						expenses or other claims of the Customer or any third party
						resulting from the suspension of the Services.
					</p>
					<p>
						The Customer agrees to use e-mail or another electronic method of
						transmission to be specified in the subscription Agreement. The
						customer must provide an e-mail address, or another agreed
						electronic method for receiving invoices and reminders.
					</p>
					<p>
						Invoices shall be paid the bank account / account number as
						specified on the invoice.
					</p>
					<p>
						Subscription fees are charged in advance. Image Systems reserves the
						right to change the price for Services for upcoming periods.
					</p>
					<p>
						Terms of payment are 30 days Exworks Linköping. VAT is added to
						given prices if applicable.
					</p>
					<p>Penalty interest is 12 (twelve) percentage.</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>5. Access rights</strong>
					</p>
					<p>
						When the Customer has bought a subscription of the Services and
						accepted the terms and conditions the Customer has the right to use
						the Services during the subscription period for as many users as the
						Customer bought the subscription for. The Customer can with his own
						choice buy the right to more users using the Services, or the right
						to use fully or partially integrated Additional services according
						to the prevailing price list or agreement.
					</p>
					<p>
						Only users with a paid and valid subscription have the right to use
						the Services. User Licenses are issued for specific named users.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>6. Data processing and privacy</strong>
					</p>
					<p>
						<strong>6.1 Treatment of personal data</strong>
					</p>
					<p>
						The Customer's use of the Services may lead to the transfer of
						personal data from the Customer to Image Systems. The Customer is
						therefore (according to the EU General Data Protection Regulation
						"GDPR") considered to be controller and Image Systems to be
						processor. The GDPR provides that a contract "Data Processing
						Agreement" shall be entered into between the controller and the
						processor as regards the transfer and processing with i.a. the
						instructions that shall apply to the processing. The Data Processing
						Agreement that shall apply to the Customer's use of the Services is
						available on www.imagesystems.se.
					</p>

					<p>
						<strong>6.2 Customer Data</strong>
					</p>
					<p>
						The Customer holds all rights to the Customer's data and Image
						Systems obtains no rights, in addition to that of these terms and
						conditions, to the Customer Data or any part thereof. Image Systems
						has the right during the term of the agreement to use Customer Data
						to deliver services to the Customer.
					</p>

					<p>
						<strong>6.3 Information security</strong>
					</p>
					<p>
						If not otherwise stated in the terms and conditions, Image Systems
						will not sell, rent, rent out or in other ways make any received
						data from the Customer available to third parties, except in the
						following situations; (I) to follow law, provision or regulation, or
						to respond to a final request from the authorities or the police,
						such as a court order, decision or injunction; (Ii) to investigate
						or prevent security threats or fraud; (Iii) in the event of
						reorganization, merger, sale or purchase of all or part of Image
						Systems when personal information may be disclosed as part of the
						reorganization, the merger or to actual and future buyers. Image
						Systems will in all such cases ensure that such parties observe the
						terms as follows here, and notify that such information has been
						given out.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>7. Support</strong>
					</p>
					<p>
						Image Systems constantly develops its products for Customers to have
						access to as good software as possible.
					</p>
					<p>
						The subscription ensures that the Customer has access to the latest
						versions of the software and also is entitled to help.
					</p>
					<p>
						The Agreement gives the right to administrative support related to
						problems with the product.
					</p>
					<p>
						Image Systems provides product support via Internet, e-mail and
						phone. The preferable way of receiving support is to send an e-mail
						to support@imagesystems.se describing the problem.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>8. Immaterial rights</strong>
					</p>
					<p>
						Image Systems is the sole owner of all intellectual property rights
						(IPR) related to the Services. IPR includes but is not limited to
						copyrights, patents, trademarks, trade names, designs and product
						designs, source code, databases, business plans and know-how,
						whether it is registered or not. All documentation, including
						manuals, user guides, or other written, electronic or
						non-electronic, descriptions of how the Services are established and
						used (Documentation) is considered part of the Services and is
						subject to the same restrictions. All copyrights, trademarks,
						registered trademarks, product names, company names or logos
						mentioned in the Services or in connection with the Services are
						considered the property of the respective owner.
					</p>
					<p>
						Image Systems claims no intellectual property rights or proprietary
						rights of any kind, to the data owned by the Customer.
					</p>
					<p>
						If Image Systems provides products licensed from another supplier
						than Image Systems, the other provider's license applies before
						these terms and conditions.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>9. Secrecy</strong>
					</p>
					<p>
						Each party undertakes not to any third party without prior written
						consent disclose such information if the counter party activities
						may be regarded as business or professional secrets or which by law
						is covered by confidentiality ("Confidential Information").
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>10. System changes and feature updates</strong>
					</p>
					<p>
						Image Systems informs its users by e-mail and publication on the
						web-page www.imagesystems.se about updates and new available
						versions of the Product. Therefore, all registered users accept that
						their name and email used for the registration are added to the
						technical newsletter mailing list.
					</p>
				</div>

				<div className={style.section}>
					<p>
						<strong>11. Contracting Parties and applicable law</strong>
					</p>
					<p>
						The parties' rights and obligations shall be entirely governed by
						Swedish law. Disputes concerning the interpretation of the terms and
						conditions or use of the Services, the parties shall try to resolve
						amicably. If dispute cannot be solved this way, the dispute shall be
						settled by arbitration administered by the Stockholm Chamber of
						Commerce Arbitration Institute (the Institute). If the dispute does
						not exceed the target value of SEK 100 000 the Institute's Rules for
						Expedited Arbitration shall apply. If the dispute exceeds SEK 100
						000 the Arbitration Rules of the Stockholm Chamber of Commerce
						Arbitration Institute apply. If the dispute reaches an amount
						between SEK 100 000 and SEK 1 million the Arbitral Tribunal shall
						consist of a sole arbitrator. If the dispute exceeds the value of
						SEK 1 000 000 the Arbitral Tribunal shall consist of three
						arbitrators. The disputes value includes the claims made in the
						Request for Arbitration and any counterclaims in the Respondent's
						reply to the Request for Arbitration.
					</p>
				</div>
			</div>
		</Modal>
	);
};
