import { FC } from 'react';
import { ImageSystemsLogo, LogoCentered } from 'assets/icons/svg';
import style from 'assets/styles/sign.module.scss';
import { useAppDispatch } from 'hooks/hooks';
import { loginAsGuest } from 'store/slices/auth';
import { isDesktop } from 'desktop';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import McSpinner from 'components/MotionCloudSpinner';
import { LoginForm } from 'modules/LoginForm';

export const Login: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const loginAsGuestHandler = async () => {
		dispatch(loginAsGuest());
	};

	const { isLoading } = useAuth0();

	return (
		<div className={style.container}>
			<div className={`loginimage ${style.leftHalfContainer}`}>
				<div className={style.leftWrapper}>
					<div className={style.logo}>
						<LogoCentered />
					</div>
					<div className={style.company}>
						<ImageSystemsLogo />
					</div>
				</div>
			</div>
			<div className={style.rightHalfContainer}>
				<div className={style.signFormWrapper}>
					{isLoading ? (
						<McSpinner />
					) : (
						<>
							<div className={style.header}>
								<p className={style.headerText}>Sign In</p>
								{isDesktop && (
									<p className={style.guestText}>
										Continue as a{' '}
										<span className={style.guest} onClick={loginAsGuestHandler}>
											Guest
										</span>
									</p>
								)}
							</div>

							<LoginForm />

							<div className={style.infoSignup}>
								<div>Don't have an account?</div>
								<div className={style.link} onClick={() => navigate('/signup')}>
									SIGN UP NOW
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
