import 'assets/styles/RecentFileCard.scss';
import { IconEmptyCube } from 'assets/icons/IconEmptyCube';

import { FC, ReactNode } from 'react';
import { FileSource, ipc } from 'desktop';

interface CardProps {
	recentFile: {
		image: ReactNode;
		name: string;
		timeStamp: string;
		fileSource: FileSource;
	};
}

export const RecentFileCard: FC<CardProps> = ({ recentFile }) => {
	return (
		<div
			className="recentCard-container"
			onClick={() =>
				ipc.openSessionFile(recentFile.name, recentFile.fileSource)
			}
		>
			<div className="recentTitle-container">
				<div className="recentIcon-container">
					{recentFile.image === null ? (
						<IconEmptyCube size={24} />
					) : (
						recentFile.image
					)}
				</div>
				<h4 className="recentTitle">{recentFile.name}</h4>
			</div>
			<div className="recentDescription-container">
				<div className="recentDescription">{recentFile.timeStamp + ' ago'}</div>
			</div>
		</div>
	);
};
