import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { App, Select } from 'antd';
import { useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/selectEngine.module.scss';
import { CloudIcon, MonitorIcon, StarIcon } from 'assets/icons/svg';
import { Cond } from 'utils/Cond';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ipc, isDesktop } from 'desktop';
import enginesService from 'services/EnginesService';
import { errorNotification } from 'utils/Notifications';
import { authState } from 'store/slices/auth';

interface Props {
	setSelectedEngine: Dispatch<SetStateAction<EngineOption | undefined>>;
	selectedEngine?: EngineOption | undefined;
}

export interface EngineOption {
	name: string;
	version: string;
	installed: { appCodes: string[]; installPath: string } | undefined;
	online: { engineId: number } | undefined;
	featured: boolean;
}

export const SelectEngineForHome: React.FC<Props> = (props) => {
	const { isOnline, user } = useAppSelector(authState);
	const { setSelectedEngine, selectedEngine } = props;
	const isGuest = user.role === 'GUEST';

	const { notification } = App.useApp();

	const onChange = (value: string) => {
		const engineFound = engines.find((engine) => engine.name === value)!;
		setSelectedEngine(engineFound);
	};

	const {
		data: installedEngines,
		error: fetchInstalledEnginesError,
		isLoading: loadingInstalledEngines,
	} = useQuery({
		queryKey: ['engines', 'home', 'installed'],
		queryFn: () => ipc.getInstalledEngines().then((res) => res),
		initialData: [],
		enabled: isDesktop,
		placeholderData: keepPreviousData,
	});

	const {
		data: onlineEngines,
		error: fetchPublishedEnginesError,
		isLoading: loadingPublishedEngines,
	} = useQuery({
		queryKey: ['engines', 'home', 'online'],
		queryFn: () => enginesService.getPublishedEngines().then((res) => res.data),
		initialData: [],
		enabled: isOnline && !isGuest,
		placeholderData: keepPreviousData,
	});

	// Combine installed engines and online available engines
	const engines: EngineOption[] = useMemo(() => {
		const onlyOnline: EngineOption[] = [];
		const bothInstalledAndOline: EngineOption[] = [];
		const onlyInstalled: EngineOption[] = [];

		onlineEngines
			.sort((a, b) =>
				// Sort engines available online by release date
				a.releaseDate !== undefined && b.releaseDate !== undefined
					? Date.parse(b.releaseDate) - Date.parse(a.releaseDate)
					: 0
			)
			.forEach((onlineEngine) => {
				const installedEngine = installedEngines.find(
					(installedEngine) => installedEngine.name === onlineEngine.name
				);

				const engine: EngineOption = {
					name: onlineEngine.name,
					version: onlineEngine.version ?? '',
					installed: !!installedEngine
						? {
								appCodes: installedEngine.apps,
								installPath: installedEngine.install_path,
						  }
						: undefined,
					online: { engineId: onlineEngine.id },
					featured:
						onlineEngine.featured !== undefined ? onlineEngine.featured : false,
				};

				!!installedEngine
					? bothInstalledAndOline.push(engine)
					: onlyOnline.push(engine);
			});

		installedEngines.forEach((installedEngine) => {
			const isOnline = bothInstalledAndOline.some(
				(engine) => engine.name === installedEngine.name
			);

			const engine: EngineOption = {
				name: installedEngine.name,
				version: installedEngine.version,
				installed: {
					appCodes: installedEngine.apps,
					installPath: installedEngine.install_path,
				},
				online: undefined,
				featured: false,
			};

			// If it is online then it will already be in the bothInstalledAndOnline array from the previous step
			// and shouldn't be placed inside the onlyInstalled array
			if (!isOnline) onlyInstalled.push(engine);
		});

		return [
			...onlyOnline,
			...bothInstalledAndOline,
			...onlyInstalled.sort((a, b) =>
				// Sort installed (inofficial) engines alphanumerically decreasing
				b.name.localeCompare(a.name, 'en', { numeric: true })
			),
		];
	}, [installedEngines, onlineEngines]);

	// set selected engine to featured if it exist
	useEffect(() => {
		if (engines.length > 0) {
			const engine = engines.find((engine) => {
				return engine.featured;
			});

			setSelectedEngine(engine !== undefined ? engine : engines.at(0));
		}
	}, [engines, setSelectedEngine]);

	const loading = loadingInstalledEngines || loadingPublishedEngines;

	useEffect(() => {
		if (!fetchInstalledEnginesError) return;
		notification.error(errorNotification('Failed to fetch installed engines!'));
	}, [fetchInstalledEnginesError, notification]);

	useEffect(() => {
		if (!fetchPublishedEnginesError) return;
		notification.error(
			errorNotification('Failed to fetch published engines online!')
		);
	}, [fetchPublishedEnginesError, notification]);

	return (
		<div className={style.selectEngineWrapper}>
			<Select
				loading={loading}
				className={style.selectEngineField}
				placeholder="Select an engine"
				value={selectedEngine?.name}
				onChange={onChange}
				getPopupContainer={(trigger) => trigger.parentNode}
				options={engines.map((engine) => ({
					label: (
						<>
							<div className={style.labelIconName}>
								<Cond if={!!engine.online}>
									<CloudIcon size={22} zoomResize />
								</Cond>
								<Cond if={!!engine.installed}>
									<MonitorIcon zoomResize />
								</Cond>
								<Cond if={engine.featured}>
									<StarIcon />
								</Cond>
								<div>{engine.name} </div>
							</div>
							<div>{engine.version}</div>
						</>
					),
					value: engine.name,
				}))}
			/>
		</div>
	);
};
