import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { InputDate, InputField, InputTextarea } from 'components/fields';
import { SelectCustomer, SelectField } from 'components/selects';
import { Controller, useForm } from 'react-hook-form';
import { editSubscriptionSchema } from 'validations/FormValidation';
import { LicensesBlock } from './LicensesBlock';
import {
	AdminSubscriptionDto,
	EditSubscriptionDto,
	SubscriptionType,
	SubscriptionTypeVals,
} from 'api';
import { SubscriptionStatus } from 'api';
import { McButton } from 'components/mc';
import { SubscriptionStatuses } from 'types/api';
import { App, Spin } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { getRequestError } from 'utils/errors';

interface EditSubscriptionFormProps {
	subscription: AdminSubscriptionDto | 'new';
	hasOpenRequests?: boolean;
	hideForm: () => void;
}

interface FormValues {
	subscriptionId: string;
	renewalDate: string;
	adminNotes: string;
}

export const EditSubscriptionForm: React.FC<EditSubscriptionFormProps> = ({
	subscription,
	hasOpenRequests,
	hideForm,
}) => {
	const isNew = subscription === 'new';
	const queryClient = useQueryClient();
	const {
		register,
		formState: { errors },
		control,
		handleSubmit,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editSubscriptionSchema),
		defaultValues: {
			renewalDate: isNew ? undefined : subscription.renewalDate,
			subscriptionId: isNew ? undefined : subscription.subscriptionID,
			adminNotes: isNew ? undefined : subscription.adminNotes,
		},
	});

	const { notification } = App.useApp();

	const [status, setStatus] = useState<SubscriptionStatus>(
		isNew ? SubscriptionStatuses.ACTIVE : SubscriptionStatuses.INACTIVE
	);

	const [seats, setSeats] = useState<number>(1);

	const [userId, setUserId] = useState<number | undefined>(undefined);

	const [licenseType, setLicenseType] = useState<SubscriptionType>(
		SubscriptionTypeVals.LOCAL
	);

	useEffect(() => {
		if (!!subscription && !isNew) {
			setValue('subscriptionId', subscription.subscriptionID ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('renewalDate', subscription.renewalDate ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('adminNotes', subscription.adminNotes ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});

			if (!!subscription.ownerInfo) setUserId(subscription.ownerInfo.userId);
			if (!!subscription.status) setStatus(subscription.status);
			if (!!subscription.type) setLicenseType(subscription.type);
			if (!!subscription.seats) setSeats(subscription.seats);
		}
	}, [subscription, setValue, isNew]);

	const { mutate: addSubscription } = useMutation({
		mutationFn: (data: FormValues) =>
			subscriptionsService.createSubscription({
				userId: userId,
				status: status,
				renewalDate: data.renewalDate,
				subscriptionID: data.subscriptionId,
				seats: licenseType === SubscriptionTypeVals.NETWORK ? seats : 1,
				type: licenseType,
				adminNotes: data.adminNotes,
			}),
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification(data.subscriptionId));
			queryClient.invalidateQueries({
				queryKey: ['subscriptions'],
			});
			hideForm();
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
				setFormError('subscriptionId', {
					type: 'custom',
					message: 'Subscription ID already in use!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'create', 'subscripton')
			);
		},
	});

	const { mutate: updateSubscription } = useMutation({
		mutationFn: ({
			id,
			editSubscriptionDto,
		}: {
			id: number;
			editSubscriptionDto: EditSubscriptionDto;
		}) => subscriptionsService.updateSubscription(editSubscriptionDto, id),
		onSuccess: (_, data) => {
			notification.success(
				saveSuccessNotification(data.editSubscriptionDto.subscriptionID)
			);
			queryClient.invalidateQueries({
				queryKey: ['subscriptions'],
			});
			hideForm();
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
				setFormError('subscriptionId', {
					type: 'custom',
					message: 'Subscription ID already in use!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'update', 'subscription')
			);
		},
	});

	const updateSubscriptionHandler = (data: FormValues) => {
		// TODO: update with new variables
		if (
			isNew ||
			(userId === subscription.userId &&
				data.subscriptionId === subscription.subscriptionID &&
				data.renewalDate === subscription.renewalDate &&
				status === subscription.status &&
				licenseType === subscription.type &&
				seats === subscription.seats &&
				data.adminNotes === subscription.adminNotes)
		) {
			return;
		}

		const dto: EditSubscriptionDto = {
			userId: userId !== subscription.userId ? userId : undefined,
			subscriptionID:
				data.subscriptionId !== subscription.subscriptionID
					? data.subscriptionId
					: undefined,
			renewalDate:
				data.renewalDate !== subscription.renewalDate
					? data.renewalDate
					: undefined,
			status: status !== subscription.status ? status : undefined,
			type: licenseType !== subscription.type ? licenseType : undefined,
			seats: seats !== subscription.seats ? seats : undefined,
			adminNotes:
				data.adminNotes !== subscription.adminNotes
					? data.adminNotes
					: undefined,
		};

		updateSubscription({ id: subscription.id, editSubscriptionDto: dto });
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		hideForm();
	};

	const [isLicenseBlockLoading, setLicenseBlockLoading] =
		useState<boolean>(false);

	const [isCustomerSelectLoading, setCustomerSelectLoading] =
		useState<boolean>(false);

	const isFormLoading: boolean =
		isLicenseBlockLoading || isCustomerSelectLoading;

	return (
		<>
			<div
				style={{
					display: isFormLoading ? 'flex' : 'none',
					justifyContent: 'center',
					margin: '24px 0',
				}}
			>
				<Spin spinning={isFormLoading} size="large" />
			</div>
			<div
				className={isNew ? style.addFormWrapper : style.editFormWrapper}
				style={isFormLoading ? { display: 'none' } : {}}
			>
				<form
					onSubmit={handleSubmit((data) => {
						isNew ? addSubscription(data) : updateSubscriptionHandler(data);
					})}
				>
					<div className={style.editForm} style={{ flexDirection: 'column' }}>
						{subscription !== 'new' && (
							<div className={style.row}>
								<div style={{ color: 'var(--text-disabled)' }}>
									Entity id: {subscription.id}
								</div>
							</div>
						)}
						<div className={style.row}>
							<div style={{ flex: 1 }}>
								<Controller
									name="subscriptionId"
									control={control}
									render={({ field }) => (
										<InputField
											placeholder={'Subscription ID'}
											{...field}
											label={`Subscription ID`}
											error={!!errors.subscriptionId}
											errorMessage={errors.subscriptionId?.message}
										/>
									)}
								/>
							</div>
							<div style={{ flex: 1 }}>
								<SelectField
									defaultValue={SubscriptionStatuses.ACTIVE}
									options={[
										{ label: 'Active', value: SubscriptionStatuses.ACTIVE },
										{ label: 'Inactive', value: SubscriptionStatuses.INACTIVE },
									]}
									label={'Status'}
									value={status}
									setSelectedField={setStatus}
								/>
							</div>
						</div>
						<div className={style.row}>
							<div style={{ flex: 4 }}>
								<Controller
									name="renewalDate"
									control={control}
									render={({ field }) => (
										<>
											<InputDate
												label={'Renewal date (UTC)'}
												{...field}
												isUtc
												error={errors.renewalDate === undefined ? false : true}
												errorMessage={errors.renewalDate?.message}
												setDate={(date) => setValue('renewalDate', date)}
												timeRemainingSuffix
												timeRemainingIncludeTarget
											/>
											<input type="hidden" {...register('renewalDate')} />
										</>
									)}
								/>
							</div>
							<div style={{ flex: 4 }}>
								<SelectCustomer
									userId={userId}
									setUserId={setUserId}
									setIsLoading={setCustomerSelectLoading}
								/>
							</div>
							<div style={{ flex: 2 }}>
								<SelectField
									defaultValue={SubscriptionTypeVals.LOCAL}
									options={[
										{ label: 'Standalone', value: SubscriptionTypeVals.LOCAL },
										{ label: 'Floating', value: SubscriptionTypeVals.NETWORK },
									]}
									label={'License Type'}
									value={licenseType}
									setSelectedField={setLicenseType}
									onChange={(val) => {
										setLicenseType(val);
										if (val === SubscriptionTypeVals.LOCAL) {
											setSeats(1);
										}
									}}
								/>
							</div>

							<div style={{ flex: 2 }}>
								<InputField
									value={
										licenseType === SubscriptionTypeVals.NETWORK ? seats : 1
									}
									type="number"
									label="Number of seats"
									onChange={(newVal) => {
										if (licenseType === SubscriptionTypeVals.NETWORK) {
											const value: number = parseInt(newVal.target.value) ?? 1;
											setSeats(value > 1 ? value : 1);
										} else {
											setSeats(1);
										}
									}}
									disabled={licenseType === SubscriptionTypeVals.LOCAL}
								/>
							</div>
						</div>
						<div className={style.row}>
							<Controller
								name="adminNotes"
								control={control}
								render={({ field }) => (
									<InputTextarea
										{...field}
										placeholder={'Description'}
										label={'Admin Notes (max 1000 characters)'}
										error={errors.adminNotes === undefined ? false : true}
										errorMessage={errors.adminNotes?.message}
									/>
								)}
							/>
						</div>
					</div>
					<McButton.Row
						style={{ justifyContent: 'flex-end', marginBottom: '1.5rem' }}
					>
						<McButton onClick={cancel}>Cancel</McButton>
						<McButton primary type="submit">
							Save
						</McButton>
					</McButton.Row>
				</form>
				{!isNew && (
					<>
						<div className={style.divider}></div>
						<LicensesBlock
							setIsLoading={setLicenseBlockLoading}
							hasOpenRequests={hasOpenRequests}
							subscription={subscription}
						/>
					</>
				)}
			</div>
		</>
	);
};
