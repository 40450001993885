import { createSlice, createAction } from '@reduxjs/toolkit';

import { RootState, AppDispatch } from 'store';
import { getErrorMessageAnd, getRequestError } from 'utils/errors';
import { PagedRequestParams } from 'types';
import {
	AddContainerDto,
	ContainerDto,
	EditSubscriptionDto,
	FullDataLicenseDto,
	LicensingRequestListDto,
	SubscriptionDto,
	UserDto,
	LicensingRequestStatus,
	ModuleItemDto,
	UpdateLicenseDto,
} from 'api';
import subscriptionsService from 'services/SubscriptionsService';
import usersService from 'services/UsersService';
import {
	StateWithLoading,
	createAppAsyncThunk,
	getLoadingStateReducers,
} from 'utils/rtk';
import licensesService from 'services/LicenseService';
import modulesService from 'services/ModulesService';
import { SubscriptionStatus } from 'types/api';

type Loadables =
	| 'list'
	| 'licenses'
	| 'save'
	| 'saveLicense'
	| 'containers'
	| 'saveContainer'
	| 'requests'
	| 'saveRequest'
	| 'modules';

export interface SubscriptionsState extends StateWithLoading<Loadables> {
	subscriptions: SubscriptionDto[];
	selectedSubscription: SubscriptionDto | null;
	selectedSubscriptionId: number | null;
	currentPage: number;
	subscriptionCount: number;
	pageCount: number;
	licenses: FullDataLicenseDto[];
	containers: ContainerDto[];
	users: UserDto[];
	requests: LicensingRequestListDto[];
	modules: ModuleItemDto[];
}

const initialState: SubscriptionsState = {
	subscriptions: [],
	selectedSubscription: null,
	selectedSubscriptionId: null,
	loadingStates: {
		list: {},
		licenses: {},
		save: {},
		saveLicense: {},
		containers: {},
		saveContainer: {},
		requests: {},
		saveRequest: {},
		modules: {},
	},
	subscriptionCount: 0,
	pageCount: 1,
	currentPage: 0,
	licenses: [],
	containers: [],
	users: [],
	requests: [],
	modules: [],
};

// Helper to inject state into action payload creator
const withState = <T>(
	getState: () => unknown,
	cb: (state: SubscriptionsState) => T
): T => cb((getState() as RootState).subscriptions);

// == Subscriptions
export const getSubscriptions = createAppAsyncThunk(
	'subscriptions/getSubscriptions',
	(
		params: [PagedRequestParams, SubscriptionStatus?, LicensingRequestStatus?],
		{ rejectWithValue }
	) =>
		subscriptionsService
			.getPaginatedSubscriptions(
				params[0].page,
				params[0].size,
				params[0].searchedValue
			)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const listReducers = getLoadingStateReducers('list');

export const addSubscription = createAppAsyncThunk(
	'subscriptions/addSubscription',
	(params: SubscriptionDto, { rejectWithValue }) =>
		subscriptionsService
			.createSubscription(params)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const addReducers = getLoadingStateReducers('save');

export const editSubscription = createAppAsyncThunk(
	'subscriptions/editSubscription',
	(params: [EditSubscriptionDto, number], { rejectWithValue }) =>
		subscriptionsService
			.updateSubscription(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const editReducers = getLoadingStateReducers('save');

export const deleteSubscription = createAppAsyncThunk(
	'subscriptions/deleteSubscription',
	(subscriptionId: number, { rejectWithValue }) =>
		subscriptionsService
			.deleteSubscriptionById(subscriptionId)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const deleteReducers = getLoadingStateReducers('save');

// == Licenses
export const getLicenses = createAppAsyncThunk(
	'subscriptions/getLicenses',
	(_, { rejectWithValue, getState }) =>
		withState(getState, (state) =>
			state.selectedSubscriptionId == null
				? Promise.resolve([])
				: subscriptionsService
						.getAllLicenses(state.selectedSubscriptionId)
						.then((r) => r.data)
						.catch((e: unknown) => rejectWithValue(getRequestError(e)))
		)
);
const licensesReducers = getLoadingStateReducers('licenses');

export const addLicense = createAppAsyncThunk(
	'subscriptions/addLicense',
	(
		{ moduleId, subscriptionId }: { moduleId: number; subscriptionId: number },
		{ rejectWithValue }
	) =>
		subscriptionsService
			.createLicenses([moduleId], subscriptionId)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const addLicenseReducers = getLoadingStateReducers('saveLicense');

export const deleteLicense = createAppAsyncThunk(
	'subscriptions/deleteLicense',
	(licenseId: number, { rejectWithValue }) =>
		licensesService
			.deleteLicenseItemById(licenseId)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const deleteLicenseReducers = getLoadingStateReducers('saveLicense');

export const updateLicense = createAppAsyncThunk(
	'subscriptions/updateLicense',
	(
		{ licenseId, dto }: { licenseId: number; dto: UpdateLicenseDto },
		{ rejectWithValue }
	) =>
		licensesService
			.updateLicense(dto, licenseId)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const updateLicenseReducers = getLoadingStateReducers('saveLicense');

// == Containers
export const getContainers = createAppAsyncThunk(
	'subscriptions/getContainers',
	(_, { rejectWithValue, getState }) =>
		withState(getState, (state) =>
			state.selectedSubscriptionId == null
				? Promise.resolve([])
				: subscriptionsService
						.getAllContainers(state.selectedSubscriptionId)
						.then((r) => r.data)
						.catch((e: unknown) => rejectWithValue(getRequestError(e)))
		)
);
const containersReducers = getLoadingStateReducers('containers');

export const addContainer = createAppAsyncThunk(
	'subscriptions/addContainer',
	(containerDto: AddContainerDto, { rejectWithValue, getState }) => {
		const state = getState() as RootState;
		return subscriptionsService
			.addContainer(containerDto, state.subscriptions.selectedSubscriptionId!)
			.catch(getErrorMessageAnd(rejectWithValue));
	}
);
const addContainerReducers = getLoadingStateReducers('saveContainer');

// == Modules
export const getModules = createAppAsyncThunk(
	'subscriptions/getModules',
	({ page, searchedValue, size }: PagedRequestParams, { rejectWithValue }) =>
		modulesService
			.getModulesList(page, size, searchedValue)
			.then((r) => r.data.content)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const modulesReducers = getLoadingStateReducers('modules');

// == Users
export const getUsers = createAppAsyncThunk(
	'subscriptions/getUsers',
	({ page, searchedValue, size }: PagedRequestParams, { rejectWithValue }) =>
		usersService
			.getUsersList(page, size, undefined, undefined, searchedValue)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const listUsersReducers = getLoadingStateReducers('list');

// == Misc
export const resetState = createAction('RESET');
export const setSelected = createAction<number | null>(
	'subscriptions/setSelected'
);
export const setSelectedAndFetch =
	(subscriptionId: number | null) => (dispatch: AppDispatch) => {
		dispatch(setSelected(subscriptionId));
		dispatch(getLicenses());
		dispatch(getContainers());
	};

const subscriptionsSlice = createSlice({
	name: 'subscriptions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSubscriptions.pending, listReducers.pending)
			.addCase(getSubscriptions.rejected, listReducers.rejected)
			.addCase(getSubscriptions.fulfilled, (state, { payload }) => {
				listReducers.fulfilled(state);

				state.subscriptions = payload.data.content;
				state.subscriptionCount = payload.data.allElements;
				state.pageCount = payload.data.allPages;
				state.currentPage = payload.data.pageNumber;
			})

			.addCase(addSubscription.pending, addReducers.pending)
			.addCase(addSubscription.rejected, addReducers.rejected)
			.addCase(addSubscription.fulfilled, addReducers.fulfilled)

			.addCase(editSubscription.pending, editReducers.pending)
			.addCase(editSubscription.rejected, editReducers.rejected)
			.addCase(editSubscription.fulfilled, editReducers.fulfilled)

			.addCase(deleteSubscription.pending, deleteReducers.pending)
			.addCase(deleteSubscription.rejected, deleteReducers.rejected)
			.addCase(deleteSubscription.fulfilled, deleteReducers.fulfilled)

			.addCase(getLicenses.pending, (state) => {
				licensesReducers.pending(state);
				state.licenses = [];
			})
			.addCase(getLicenses.rejected, licensesReducers.rejected)
			.addCase(getLicenses.fulfilled, (state, { payload }) => {
				licensesReducers.fulfilled(state);

				state.licenses = payload;
			})

			.addCase(addLicense.pending, addLicenseReducers.pending)
			.addCase(addLicense.rejected, addLicenseReducers.rejected)
			.addCase(addLicense.fulfilled, addLicenseReducers.fulfilled)

			.addCase(deleteLicense.pending, deleteLicenseReducers.pending)
			.addCase(deleteLicense.rejected, deleteLicenseReducers.rejected)
			.addCase(deleteLicense.fulfilled, deleteLicenseReducers.fulfilled)

			.addCase(updateLicense.pending, updateLicenseReducers.pending)
			.addCase(updateLicense.rejected, updateLicenseReducers.rejected)
			.addCase(updateLicense.fulfilled, updateLicenseReducers.fulfilled)

			.addCase(getUsers.pending, listUsersReducers.pending)
			.addCase(getUsers.rejected, listUsersReducers.rejected)
			.addCase(getUsers.fulfilled, (state, { payload }) => {
				listUsersReducers.fulfilled(state);

				state.users = payload.data.content;
			})

			.addCase(getContainers.pending, (state) => {
				containersReducers.pending(state);
				state.containers = [];
			})
			.addCase(getContainers.rejected, containersReducers.rejected)
			.addCase(getContainers.fulfilled, (state, { payload }) => {
				containersReducers.fulfilled(state);

				state.containers = payload;
			})
			.addCase(addContainer.pending, addContainerReducers.pending)
			.addCase(addContainer.rejected, addContainerReducers.rejected)
			.addCase(addContainer.fulfilled, addContainerReducers.fulfilled)

			.addCase(getModules.pending, (state) => {
				modulesReducers.pending(state);
				state.requests = [];
			})
			.addCase(getModules.rejected, modulesReducers.rejected)
			.addCase(getModules.fulfilled, (state, { payload }) => {
				modulesReducers.fulfilled(state);

				state.modules = payload;
			})

			.addCase(setSelected, (state, { payload }) => {
				state.licenses = [];
				state.selectedSubscriptionId = payload;

				state.selectedSubscription =
					payload === null
						? null
						: (state.subscriptions.find(
								(s) => s.id === payload
						  ) as SubscriptionDto) ?? null;
			})
			.addCase(resetState, () => {
				return initialState;
			});
	},
});

export const subscriptionsReducer = subscriptionsSlice.reducer;
export const subscriptionsState = (state: RootState) => state.subscriptions;
