import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/hooks';
import { resetState } from 'store/slices/oneNews';

import 'assets/styles/NewsCard.scss';
import { getPublicImageURL } from 'services/ApiService';
import defaultImage from 'assets/images/top.jpg';

interface CardProps {
	news: News;
}

export const NewsCard: FC<CardProps> = ({ news }) => {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleClickToOpenNews = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		id: number
	) => {
		event.stopPropagation();
		dispatch(resetState());
		navigate('/news/' + id);
	};

	return (
		<div className="newCard-container">
			<div className="newCardImage-container">
				<img
					src={
						news.thumbnailImagePath
							? getPublicImageURL(news.thumbnailImagePath)
							: defaultImage
					}
					alt={news.title}
					className="newCardImage"
					onError={(e) => {
						e.currentTarget.src = defaultImage;
					}}
				/>
			</div>
			<div className="description-container">
				<h4 className="description-title">{news.title}</h4>
				<p className="description">{news.description}</p>
			</div>
			<div
				className="btn-container"
				onClick={(event) => {
					if (news.id) handleClickToOpenNews(event, news.id);
				}}
			>
				<button className="btn-card">Read More</button>
			</div>
		</div>
	);
};
