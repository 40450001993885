import { yupResolver } from '@hookform/resolvers/yup';
import { App } from 'antd';
import style from 'assets/styles/signForm.module.scss';
import { FormInput } from 'components/fields';
import { MotionButton } from 'components/MotionButton';
import { isDesktop } from 'desktop';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import authService from 'services/AuthService';
import { signupSchema } from 'validations/FormValidation';

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	department: string;
	passkey: string;
}

export const SignupForm = () => {
	const navigate = useNavigate();

	const { modal, notification } = App.useApp();

	const {
		formState: { errors },
		handleSubmit,
		control,
	} = useForm<FormValues>({
		resolver: yupResolver(signupSchema),
	});

	const signUpHandler: SubmitHandler<FormValues> = async (data) => {
		authService
			.signUp({
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				company: data.company,
				department: data.department,
				passkey: data.passkey,
			})
			.then(() => {
				modal.success({
					title: 'Account Created!',
					content:
						'Your account has been created and is waiting for admin approval.\n\nYou will get an email from no-reply@imagesystems.se when it has been approved!\n',
					onOk: () => navigate('/login'),
					centered: true,
				});
			})
			.catch((resp) => {
				const err = resp.response.data;

				notification.error({
					message: 'Error!',
					description:
						err.code === 'ENTITY_UNIQUE_CONFLICT' && err.target === 'email'
							? 'The provided email is already in use'
							: err.code === 'INVALID_REQUEST' && err.target === 'passkey'
							? 'The provided passkey is incorrect'
							: 'Unkown error',
					duration: 5,
					placement: isDesktop ? 'bottomLeft' : 'topRight',
				});
			});
	};

	return (
		<form className={style.signupForm} onSubmit={handleSubmit(signUpHandler)}>
			<div className={style.wrapper}>
				<Controller
					name="firstName"
					control={control}
					render={({ field }) => (
						<FormInput
							placeholder={'First Name'}
							{...field}
							label={'First Name'}
							error={errors.firstName?.message}
						/>
					)}
				/>
				<Controller
					name="lastName"
					control={control}
					render={({ field }) => (
						<FormInput
							placeholder={'Last Name'}
							{...field}
							label={'Last Name'}
							error={errors.lastName?.message}
						/>
					)}
				/>
				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<FormInput
							placeholder={'Email'}
							{...field}
							label={'Email'}
							error={errors.email?.message}
						/>
					)}
				/>
				<Controller
					name="company"
					control={control}
					render={({ field }) => (
						<FormInput
							placeholder={'Company'}
							{...field}
							label={'Company'}
							error={errors.company?.message}
						/>
					)}
				/>
				<Controller
					name="department"
					control={control}
					render={({ field }) => (
						<FormInput
							placeholder={'Department'}
							{...field}
							label={'Department'}
							error={errors.department?.message}
						/>
					)}
				/>
				<Controller
					name="passkey"
					control={control}
					render={({ field }) => (
						<FormInput
							type="password"
							placeholder={'Invitation key'}
							{...field}
							label={'Invitation key'}
							error={errors.passkey?.message}
						/>
					)}
				/>
			</div>

			{!isDesktop && <hr className={style.line} />}

			<MotionButton
				shape="round"
				size="large"
				htmlType="submit"
				width="100%"
				height="3rem"
				fontWeight="700"
			>
				SIGN UP
			</MotionButton>
		</form>
	);
};
