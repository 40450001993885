import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { initApp, isDesktop } from 'desktop';
import { setupErrorHandling } from 'sentry';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';

import './index.scss';
import './extra.scss';
import './desktop/desktop.scss';
import { SettingsProvider } from 'settings';

if (isDesktop) {
	initApp();
	const rootElement = document.querySelector(':root');
	if (rootElement) {
		rootElement.className = 'mc-desktop';
	}
}

if (document.location.hostname === 'motioncloud-dev.azurewebsites.net') {
	document.location.hostname = 'motioncloud-dev.imagesystems.se';
}

setupErrorHandling();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<BrowserRouter>
		<SettingsProvider>
			<Auth0Provider
				domain={process.env.REACT_APP_OAUTH2_DOMAIN!}
				clientId={process.env.REACT_APP_OAUTH2_CLIENT_ID!}
				authorizationParams={{
					redirect_uri: window.location.origin + '/login',
					audience: 'https://motioncloud.se/api',
					scope: 'profile email openid offline_access',
				}}
				useRefreshTokens
				cacheLocation="localstorage"
			>
				<Provider store={store}>
					<App />
				</Provider>
			</Auth0Provider>
		</SettingsProvider>
	</BrowserRouter>
);
