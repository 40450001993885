import { FC, useEffect } from 'react';
import {
	Route,
	Routes,
	Navigate,
	useNavigate,
	useLocation,
} from 'react-router-dom';

import { AppLayout } from 'modules/Layout';

import {
	Login,
	HomePage,
	LicensingPage,
	StorePage,
	NewsPage,
	SupportPage,
	TemplatesPage,
	ManageEngines,
	ManageAppReleases,
	ManageUsers,
	ManageNews,
	ManageTemplates,
	ManageLicenses,
	ManageSupport,
	ManageStatistics,
	ManageModules,
	ManageApplications,
	OneNews,
	OneApp,
	OneEngine,
	OneTemplate,
	NotFound,
	ManageOrganizations,
} from './pages';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
	authState,
	guestMode,
	loginUsingToken,
	logout,
	updateOnlineStatus,
} from 'store/slices/auth';
import { ProtectedRoute } from 'Router/ProtectedRoute';
import { AuthRoute } from 'Router/AuthRoute';
import { Preloader } from 'modules/Preloader';
import { OfflineModal } from 'modules/OfflineModal';
import { LocalContextProvider, isDesktop } from 'desktop';
import { useLocalSettings } from 'settings';
import { ConfigProvider } from 'antd';
import { Cond } from 'utils/Cond';
import { Titlebar } from 'desktop/components';
import { useLightMode } from 'hooks/useLightMode';
import { cirrusTheme, nimbusTheme } from 'themes';
import { ThemePage } from 'themes/ThemePage';
import { ComponentsPage } from 'components/mc/ComponentsPage';
import ReactGA from 'react-ga';
import { Signup } from 'pages/Signup';
import { useAuth0 } from '@auth0/auth0-react';
import { apiConfig } from 'services/ApiService';
import { App as AntdApp } from 'antd';
import { NotificationConfig } from 'antd/es/notification/interface';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ManageFeatureFlags from 'pages/ManageFeatureFlags';

const createAuthParams = (scopes: string[] | undefined) => {
	// Scoped access tokens does not work correctly, so they are disabled for now.
	// FIXME: Re-enable this to limit the scope for access tokens
	return {};

	// if (!Array.isArray(scopes)) return {};

	// Creating a new access token for the necessary scope does not work correctly on non-https, so it's disabled in development
	// if (process.env.NODE_ENV !== 'production') return {};

	// return {
	// 	scope: scopes.join(' '),
	// };
};

const nonAuthPaths = [
	'/forgot-password',
	'/reset-password',
	'/set-password',
	'/signup',
	'/login',
];

const queryClient = new QueryClient();

export const App: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, loading: apiLoading, isOnline } = useAppSelector(authState);
	const dispatch = useAppDispatch();
	const {
		isAuthenticated: hasAPIAuth,
		isLoading: authLoading,
		getAccessTokenSilently,
		getIdTokenClaims,
	} = useAuth0();

	const loading = apiLoading || authLoading;

	useEffect(() => {
		// Initialize Google Analytics and send pageview events
		ReactGA.initialize('G-NP9HZ5YSNP');
		ReactGA.pageview(window.location.pathname + window.location.search);

		// Remove window decorations if they have been added by the authentication redirect
		if (isDesktop) {
			import('@tauri-apps/api/window').then(({ appWindow }) => {
				appWindow.setDecorations(false);
			});
		}
	}, []);

	useEffect(() => {
		const handleStatusChange = () =>
			dispatch(updateOnlineStatus(navigator.onLine));

		window.addEventListener('online', handleStatusChange);
		window.addEventListener('offline', handleStatusChange);

		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, [dispatch]);

	const [authIsGuest] = useLocalSettings('isGuest');
	const [lightMode] = useLightMode();
	const isGuest = user.role === 'GUEST';
	const isAdmin = user.role === 'ADMIN';
	const showOffline = location.pathname !== '/login' && !isGuest && !isOnline;

	useEffect(() => {
		if (lightMode) {
			document.body.classList.add('lightMode');
		} else {
			document.body.classList.remove('lightMode');
		}
	}, [lightMode]);

	// Try to restore authentication and redirect to login if it's not possible to do so
	useEffect(() => {
		if (authLoading || authIsGuest || !isOnline || hasAPIAuth || isGuest)
			return;
		if (nonAuthPaths.includes(location.pathname)) return;

		getAccessTokenSilently().catch((e: Error) => {
			if (!('error' in e && e.error === 'missing_refresh_token')) {
				// Missing refresh token is expected when the user has not previously logged in
				console.error('[AUTH CHECK] Silent token error: %o', e);
			}
			navigate('/login', { replace: true });
		});
	}, [
		hasAPIAuth,
		authLoading,
		authIsGuest,
		getAccessTokenSilently,
		navigate,
		location.pathname,
		isOnline,
		isGuest,
		dispatch,
	]);

	// Update the store according to the guest/auth0 states
	useEffect(() => {
		if (authLoading) return;

		if (authIsGuest) {
			dispatch(guestMode());
		} else if (hasAPIAuth) {
			getIdTokenClaims().then(loginUsingToken).then(dispatch);
		} else {
			dispatch(logout({ isGuest: false }));
		}
	}, [hasAPIAuth, dispatch, getIdTokenClaims, authIsGuest, authLoading]);

	useEffect(() => {
		apiConfig.accessToken = async (_, scopes) => {
			const token = await getAccessTokenSilently({
				authorizationParams: createAuthParams(scopes),
			});
			return token;
		};
	}, [getAccessTokenSilently]);

	const notificationConfig: NotificationConfig = isDesktop
		? {
				stack: { threshold: 2 },
				maxCount: 3,
				placement: 'bottomRight', // bottomRight placement does not cover up as much on the desktop app
				duration: 5,
		  }
		: {
				stack: { threshold: 3 },
				maxCount: 5,
				placement: 'topRight',
				duration: 5,
		  };

	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider theme={lightMode ? cirrusTheme : nimbusTheme}>
				<LocalContextProvider>
					<AntdApp notification={notificationConfig}>
						<Cond if={isDesktop}>
							<Titlebar />
						</Cond>
						<OfflineModal show={showOffline} />
						<Routes>
							<Route
								path="login"
								element={<AuthRoute children={<Login />} />}
							/>
							<Route
								path="signup"
								element={<AuthRoute children={<Signup />} />}
							/>
							<Route
								path="/"
								element={<ProtectedRoute children={<AppLayout />} />}
							>
								<Route index element={<HomePage />} />
								{isGuest ? undefined : (
									<>
										<Route path="news/:id" element={<OneNews />} />
										<Route path="apps/:appId/:engineId?" element={<OneApp />} />
										<Route path="engines/:id" element={<OneEngine />} />
										<Route path="store" element={<StorePage />} />
										<Route path="news" element={<NewsPage />} />
										<Route path="store/apps/:id" element={<OneApp />} />
										<Route path="store/news/:id" element={<OneNews />} />
									</>
								)}
								<Route path="templates" element={<TemplatesPage />} />
								<Route path="templates/:id" element={<OneTemplate />} />
								<Route path="licensing/:id?" element={<LicensingPage />} />
								<Route path="support" element={<Navigate to="/support/qa" />} />
								<Route
									path="support/:category/:id?"
									element={<SupportPage />}
								/>

								{isAdmin ? (
									<>
										<Route
											path="admin"
											element={<Navigate to="/admin/users" />}
										/>
										<Route
											path="admin/users/:email?"
											element={<ManageUsers />}
										/>
										<Route
											path="admin/organizations/:id?"
											element={<ManageOrganizations />}
										/>
										<Route path="admin/engines" element={<ManageEngines />} />
										<Route
											path="admin/releases"
											element={<ManageAppReleases />}
										/>
										<Route path="admin/news" element={<ManageNews />} />
										<Route
											path="admin/templates"
											element={<ManageTemplates />}
										/>
										<Route
											path="admin/licenses/:id?"
											element={<ManageLicenses />}
										/>
										<Route path="admin/support" element={<ManageSupport />} />
										<Route path="admin/modules" element={<ManageModules />} />
										<Route
											path="admin/applications"
											element={<ManageApplications />}
										/>
										<Route
											path="admin/statistics"
											element={<ManageStatistics />}
										/>
										<Route
											path="admin/feature-flags"
											element={<ManageFeatureFlags />}
										/>
									</>
								) : undefined}
								{loading && <Route path="*" element={<Preloader />} />}
							</Route>
							<Route path="theme" element={<ThemePage />} />
							<Route path="components" element={<ComponentsPage />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					</AntdApp>
				</LocalContextProvider>
			</ConfigProvider>
			<ReactQueryDevtools buttonPosition="bottom-left" />
		</QueryClientProvider>
	);
};

export default App;
