import { ipc, isDesktop } from 'desktop/ipc';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { PropsWithChildren } from 'react';
import { InstalledEngine, SessionFile } from './types';
import { watch } from 'tauri-plugin-fs-watch-api';

export interface LocalState {
	isLocal: boolean;
	installedEngines: InstalledEngine[];
	recentFiles: SessionFile[];
	recentTemplates: SessionFile[];
	userTemplates: SessionFile[];
	reloadEngines: () => void;
	reloadRecentFiles: () => void;
	reloadUserTemplates: () => void;
}

const defaultState: LocalState = {
	isLocal: false,
	installedEngines: [],
	recentFiles: [],
	recentTemplates: [],
	userTemplates: [],
	reloadEngines: () => {},
	reloadRecentFiles: () => {},
	reloadUserTemplates: () => {},
};

export const DesktopContext = React.createContext<LocalState>(defaultState);
export const useDesktopContext = () => useContext(DesktopContext);

export const LocalContextProvider: React.FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	const reloadEngines = () => {
		ipc.getInstalledEngines().then((installedEngines) =>
			setLocalState((s) => ({
				...s,
				installedEngines,
			}))
		);
	};

	const reloadRecentFiles = () => {
		ipc.getRecentFiles().then((recentFiles) =>
			setLocalState((s) => ({
				...s,
				recentFiles: recentFiles.filter((f) => f.extension === 'tes'),
				recentTemplates: recentFiles.filter((f) => f.extension === 'tet'),
			}))
		);
	};

	const reloadUserTemplates = () => {
		ipc.getUserTemplates().then((userTemplates) =>
			setLocalState((s) => ({
				...s,
				userTemplates: userTemplates,
			}))
		);
	};

	const [refreshFiles, setRefreshFiles] = useState(false);

	useEffect(() => {
		reloadRecentFiles();
		reloadUserTemplates();
	}, [refreshFiles]);

	useEffect(() => {
		if (isDesktop) {
			ipc.getRecentFolder().then((folder) =>
				watch(folder, (event) => {
					setRefreshFiles((state) => !state);
				})
			);
		}
	}, []);

	const [localState, setLocalState] = useState({
		...defaultState,
		reloadEngines,
		reloadRecentFiles,
		reloadUserTemplates,
		isLocal: isDesktop,
	} satisfies LocalState);

	return (
		<DesktopContext.Provider value={localState}>
			{children}
		</DesktopContext.Provider>
	);
};
