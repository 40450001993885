import style from 'assets/styles/featuredCard.module.scss';
import { McButton } from 'components/mc';
import defaultImage from 'assets/images/top.jpg';

import { FC } from 'react';
import { To } from 'react-router-dom';
import { getPublicImageURL } from 'services/ApiService';

interface FeaturedCardProps {
	title: string;
	description?: string[];
	btnText?: string;
	imageSrcPath?: string;
	content?: string;
	btnLinkTo: To;
}

export const FeaturedCard: FC<FeaturedCardProps> = ({
	title,
	description,
	btnText,
	imageSrcPath,
	content,
	btnLinkTo,
}) => {
	const stylesContainer =
		content === 'template'
			? `${style.templateContainer}`
			: `${style.container}`;

	const stylesDescription =
		content === 'template'
			? `${style.templateDescription}`
			: `${style.description}`;

	const stylesImgContainer =
		content === 'template'
			? `${style.templateImgContainer}`
			: `${style.imgContainer}`;

	return (
		<div className={stylesContainer}>
			<div className={style.leftContainer}>
				<h2
					className={style.title}
					style={{ fontSize: title.length > 50 ? '1.2rem' : '1.5rem' }}
				>
					{title}
				</h2>
				<div className={style.descriptionContainer}>
					{description?.map((item, idx) => (
						<h4 className={stylesDescription} key={idx}>
							{item}
						</h4>
					))}
				</div>
				<div className={style.btnContainer}>
					<McButton.Route to={btnLinkTo} primary>
						{btnText}
					</McButton.Route>
				</div>
			</div>
			<div className={style.rightContainer}>
				<div className={stylesImgContainer}>
					<img
						src={imageSrcPath ? getPublicImageURL(imageSrcPath) : defaultImage}
						alt={title || 'Featured item'}
						onError={(e) => {
							e.currentTarget.src = defaultImage;
						}}
					/>
				</div>
			</div>
		</div>
	);
};
