import React, { useState, useEffect } from 'react';
import 'assets/styles/sidebar.scss';
import Icon from '@ant-design/icons';
import {
	AppReleasesIcon,
	EnginesIcon,
	NewsIcon,
	UsersIcon,
	TemplatesIcon,
	LicensesIcon,
	SupportIcon,
	ModulesIcon,
	ApplicationsIcon,
	StatisticsIcon,
	SidebarOpenCollapseIcon,
	SidebarCollapseIcon,
	OrganizationsIcon,
	FeatureFlagIcon,
} from 'assets/icons/svg';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import version from 'version.json';

import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import RedDot from 'components/RedDot';
import { useQuery } from '@tanstack/react-query';
import licensesService from 'services/LicenseService';
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
	disabled?: boolean
): MenuItem => {
	return {
		label,
		key,
		icon,
		children,
		type,
		disabled,
	} as MenuItem;
};

export const Sidebar = () => {
	const { user } = useAppSelector(authState);
	const navigate = useNavigate();
	const location = useLocation();
	const [collapsed, setCollapsed] = useState(false);
	const [showButton, setShowButton] = useState(false);

	const isAdminTab =
		user.role === 'ADMIN' && location.pathname.startsWith('/admin');

	const versionMenuItem: MenuItem = {
		label: `ver: ${version.fullVersion}`,
		key: 'version',
		disabled: true,
	};

	const { data: hasOpenRequests } = useQuery({
		queryKey: ['subscriptions', 'open'],
		queryFn: () => licensesService.hasAnyOpenRequests().then((res) => res.data),
		enabled: isAdminTab,
		initialData: false,
	});

	const sideBarMenuItemsAdmin: MenuProps['items'] = [
		getItem(
			'Categories',
			'categories',
			null,
			[
				getItem('Users', '/admin/users', <Icon component={UsersIcon} />),
				getItem(
					'Organizations',
					'/admin/organizations',
					<Icon component={OrganizationsIcon} />
				),
				getItem('Engines', '/admin/engines', <Icon component={EnginesIcon} />),
				getItem(
					'App Releases',
					'/admin/releases',
					<Icon component={AppReleasesIcon} />
				),
				getItem('News', '/admin/news', <Icon component={NewsIcon} />),
				getItem(
					'Templates',
					'/admin/templates',
					<Icon component={TemplatesIcon} />
				),
				getItem(
					'Licenses',
					'/admin/licenses',
					<>
						{hasOpenRequests && (
							<RedDot
								additionalStyle={{
									position: 'absolute',
									top: '2px',
									left: '-6px',
								}}
							/>
						)}
						<Icon component={LicensesIcon} />
					</>
				),
				getItem('Support', '/admin/support', <Icon component={SupportIcon} />),
				getItem(
					'Feature Flags',
					'/admin/feature-flags',
					<Icon component={FeatureFlagIcon} />
				),
				{ type: 'divider' },
				getItem('Modules', '/admin/modules', <Icon component={ModulesIcon} />),
				getItem(
					'Applications',
					'/admin/applications',
					<Icon component={ApplicationsIcon} />
				),
				{ type: 'divider' },
				getItem(
					'Statistics',
					'/admin/statistics',
					<Icon component={StatisticsIcon} />
				),
				{ type: 'divider' },
				versionMenuItem,
			],
			'group'
		),
	];

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1024) {
				setCollapsed(true);
				setShowButton(true);
			} else {
				setCollapsed(false);
				setShowButton(false);
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			{isAdminTab ? (
				<Sider
					theme="dark"
					breakpoint="xxl"
					collapsedWidth="78"
					width={242}
					className="sidebar"
					collapsed={collapsed}
					onCollapse={(collapsed) => setShowButton(collapsed)}
				>
					{showButton && (
						<div onClick={toggleCollapsed} className="sidebar-toggle-button">
							<Icon
								component={
									collapsed ? SidebarCollapseIcon : SidebarOpenCollapseIcon
								}
							/>
						</div>
					)}
					<Menu
						theme="dark"
						mode="inline"
						items={isAdminTab ? sideBarMenuItemsAdmin : undefined}
						selectedKeys={[location.pathname]}
						onSelect={({ key }) => {
							navigate(`../${key}`);
						}}
						className="sidebarMenu"
					/>
				</Sider>
			) : (
				<Sider
					theme="dark"
					width={0}
					collapsedWidth="0"
					trigger={null}
					className="without-sidebar"
				/>
			)}
		</>
	);
};
