import { AlertCircle, IconTickDown } from 'assets/icons/svg';
import { Section } from 'components/Section';
import { RecentFileCard } from 'components/cards';
import { timeAgo } from 'utils';
import style from 'assets/styles/homePage.module.scss';
import { AppIcon } from 'assets/icons/apps';
import { FC, useMemo, useState } from 'react';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { SessionFile } from 'desktop';
import { Cond } from 'utils/Cond';

export const RecentFilesSection: FC<{
	recentFiles: SessionFile[];
}> = ({ recentFiles }) => {
	const { user } = useAppSelector(authState);
	const isGuest = user.role === 'GUEST';
	const [showAll, setShowAll] = useState<boolean>(false);

	const allRecentFiles = useMemo(() => recentFiles.slice(0, 16), [recentFiles]); // TODO: check if this causes out of range exception
	const fewRecentFiles = useMemo(() => recentFiles.slice(0, 4), [recentFiles]);

	const filteredRecentFiles = showAll ? allRecentFiles : fewRecentFiles;

	return (
		<Section
			title="Recent files"
			customTitle={
				<div
					style={{
						display: 'inline-flex',
						flexDirection: 'row',
						gap: '1rem',
						marginBottom: '16px',
						alignItems: 'center',
					}}
				>
					<h3 className="section-title" style={{ paddingBottom: '0' }}>
						Recent Files
					</h3>
					<div
						onClick={() => setShowAll((prev) => !prev)}
						style={{
							transform: showAll ? 'rotate(0deg)' : 'rotate(180deg)',
							cursor: 'pointer',
							userSelect: 'none',
						}}
					>
						<Cond if={recentFiles.length > 4}>
							<IconTickDown />
						</Cond>
					</div>
				</div>
			}
			row
			style={isGuest ? { marginTop: '0' } : {}}
		>
			{!recentFiles.length ? (
				<div className={style.noElementsInfo}>
					<AlertCircle zoomResize />
					You don’t have any recent files
				</div>
			) : (
				<div className={style.allRecentFilesContainer}>
					{filteredRecentFiles.map((item, idx) => (
						<div className={style.recentFileCardWrapper} key={idx}>
							<RecentFileCard
								recentFile={{
									image: <AppIcon size={24} app={item.app_id} />,
									name: `${item.name}.${item.extension}`,
									timeStamp: timeAgo(item.last_modified),
									fileSource: 'RECENT',
								}}
							/>
						</div>
					))}
				</div>
			)}
		</Section>
	);
};
