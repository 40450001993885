import { AlertCircle, RightArrowIcon, LeftArrowIcon } from 'assets/icons/svg';
import { useAppSelector } from 'hooks/hooks';
import { AppsCard } from 'components/cards';
import { Section } from 'components/Section';
import { App, Spin, Carousel } from 'antd';
import style from 'assets/styles/homePage.module.scss';
import { authState } from 'store/slices/auth';
import appReleasesService from 'services/AppReleasesService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { mcErrorNotification } from 'utils/Notifications';
import applicationsService from 'services/ApplicationsService';
import React from 'react';

export const AppsToTrySection = () => {
	const { user } = useAppSelector(authState);
	const isGuest = user.role === 'GUEST';
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const {
		data: appReleases,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['applications', 'releases', 'to-try'],
		queryFn: () =>
			appReleasesService.getApplicationReleasesToTry().then((res) => res.data),
		initialData: [],
		enabled: !isGuest,
	});

	useEffect(() => {
		if (error) {
			notification.warning(
				mcErrorNotification('Warning', error, 'fetch', 'apps to try')
			);
		}
	}, [error, notification]);

	const carouselSettings = {
		dots: true,
		arrows: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: (
			<div>
				<RightArrowIcon />
			</div>
		),
		prevArrow: (
			<div>
				<LeftArrowIcon />
			</div>
		),
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
				},
			},
		],
		className: style.appsToTryCarousel,
	};

	return (
		<Section title="Apps to try" row>
			<Spin spinning={isLoading} size="default">
				{appReleases.length ? (
					<div className={style.allAppsContainer}>
						<Carousel {...carouselSettings}>
							{appReleases.map((appRelease) => (
								<div
									className={`${style.cardWrapper} ${style.sameHeightSlide}`}
									key={appRelease.id}
									onMouseEnter={() => {
										queryClient.prefetchQuery({
											queryKey: ['applications'],
											queryFn: () =>
												applicationsService
													.getApplicationsList(0, 50, undefined)
													.then((res) => res.data.content),
										});
										queryClient.prefetchQuery({
											queryKey: ['applications', 'releases', appRelease.id],
											queryFn: () =>
												appReleasesService
													.getApplicationReleaseById(appRelease.id)
													.then((res) => res.data),
										});
										queryClient.prefetchQuery({
											queryKey: [
												'engines',
												'releases',
												'latest',
												appRelease.id,
											],
											queryFn: () =>
												appReleasesService
													.getLatestEngineForApplicationRelease(appRelease.id)
													.then((res) => res.data),
										});
									}}
								>
									<AppsCard release={appRelease} />
								</div>
							))}
						</Carousel>
					</div>
				) : (
					<div className={style.noElementsInfo}>
						<AlertCircle zoomResize />
						There are currently no Applications to show here
					</div>
				)}
			</Spin>
		</Section>
	);
};
